import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Posting - Rahmenvereinbarungen",
  "description": "Rahmenvereinbarungen",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hat ein bestehender Rahmenvertrag mit einer Jobbörse Einfluss auf das Ranking in der Vorschlagsliste?`}</h2>
    <p>{`Nein. Ob aktuell ein Rahmenvertrag mit einer Jobbörse besteht hat keinen Einfluss auf das Ranking. `}</p>
    <h2>{`Werden Jobbörsen, mit denen ein Rahmenvertrag besteht, in der Vorschlagsliste angezeigt?`}</h2>
    <p>{`Ja. Jobbörsen, mit denen Sie Rahmenverträge abgeschlossen haben, sind in der Vorschlagsliste markiert. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      